<template>
  <div
    class="probit-til px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="PROBIT and TIL Analysis" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/probit1b.gif"
          width="801"
          height="1107"
          alt=""
          title="PROBIT Analysis"
          class="img-responsive wp-image-12102"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Below are descriptions of PROBIT and TIL analysis. These are ways to
            evaluate the sensitivity of energetic materials to Friction, Impact,
            and ESD impetuses. To read more about how to compare whether or not
            two samples have different sensitivities based on test results
            please see our discussion on
            <router-link
              title="Statistical Evaluation"
              to="/resources/statistical-evaluation"
              >statistical evaluation</router-link
            >
            under the Resources tab.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            PROBIT
          </h2>
          <p>
            Probability of Initiation (PROBIT) Analysis is a statistical method
            that relates reaction frequency to a statistical probability of
            initiation; enabling quantitative or numerical evaluation of an
            energetic material’s sensitivity. With this information, a
            material’s sensitivity can be directly measured against potential
            in-process energy levels. This enables the user to design and
            control processes that maintain adequate safety factors to help
            protect personnel, equipment, and facilities.
          </p>
          <p>
            Other methods typically compare the sensitivity of an energetic
            material relative to a reference substance (e.g. dry PETN or RDX).
            Such qualitative comparisons cannot predict the behavior of the
            material to stimulus energies encountered in processing operations,
            handling operations, or maintenance activities.
          </p>
          <p>
            PROBIT Analysis is an invaluable tool that provides simple and
            direct analysis of a material’s sensitivity. Further, PROBIT
            analysis may be used in conjunction with an analysis of the
            In-Process Potential (IPP) to evaluate the probability of initiation
            and margins of safety due to potential stimulus conditions from (1)
            processing operations, (2) normal handling operations, and (3)
            inadvertent dropping of tools or equipment.
          </p>
          <p>
            The product of the PROBIT analysis is a curve and corresponding
            mathematical equation that defines the probability of initiation
            (x-axis) as a function of calculated stimulus energy (y-axis), as
            shown below for Modified Bureau of Mines Impact data (shown as
            circles):
          </p>
          <p>
            <em
              >The curve represents the 95% confidence interval (95% confident
              that the curve represents the maximum probability of initiation as
              a function of stimulus energy) while the straight line represents
              the 50% line (50% of the test data is above the line and 50% is
              below the line). The test data appears as small circles on the
              plot.</em
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            TIL Analysis
          </h2>
          <p>
            The Threshold Initiation Level (TIL) represents the minimum amount
            of energy required to initiate a sample. The TIL is first determined
            through standardized impact, friction, and/or ESD testing. Because
            testing is typically limited to discrete energy levels, the TIL
            resulting from laboratory testing is usually an approximate value.
            PROBIT analysis further refines the TIL to a calculated statistical
            value. Statistically, the TIL represents the energy level which has
            a 3.4% probability of initiation for the given test material.
          </p>
          <p>
            When calculating the TIL through testing, the tests are performed at
            a series of input energy levels. The test begins at a predetermined,
            low stimulus level. If the test produces two consecutive “no-go”
            reactions, the test is continued at the next higher standard level.
            If the event results in a “go” reaction, the test is continued at
            the next lower standard level. After the first “go” reaction occurs,
            the test continues at each subsequently lower energy level until 20
            “no-go” responses are observed at an energy level and at least one
            “go” response was observed at the next higher standard input level
            (out of 20 trials if necessary). An example test series is shown
            below.
          </p>
          <p>
            Once the TIL for a substance has been determined, a PROBIT analysis
            of the material can be conducted. At least 10 trials are performed
            at each of four different energy levels above the TIL (a 20 trial
            PROBIT is preferred for higher accuracy). The data received from
            these trials are statistically analyzed. The resulting PROBIT curve
            represents the probability of initiation as a function of the input
            stimulus. The results are used to calculate margins of safety and
            the probability of initiation of the material for a given stimulus.
            The curve obtained from the PROBIT is a more accurate representation
            of the reactivity of the material than obtainable from the TIL
            alone, and can be extrapolated to regions of low reaction
            probability.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          ></h2>
        </Paragraph>
      </div>
    </div>
    <TitleComponent title="TIL and PROBIT Example" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/probit2b.gif"
          width="979"
          height="635"
          alt=""
          title="PROBIT and TIL"
          class="img-responsive wp-image-12104"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            The following example is a hypothetical test series for determining
            a TIL and collecting PROBIT data. The steps detailed below explain
            how this hypothetical case proceeded.<br />
            Sample PROBIT/TIL Test Series Sheet
          </p>
          <p>The first objective is to establish a TIL:</p>
          <ol>
            <li>
              Select a level at which no reaction is anticipated and perform two
              trials (in this case, energy level 600 was selected).
            </li>
            <li>
              Since no reactions were observed in two trials, the next higher
              energy level (700) was selected.
            </li>
            <li>
              Since no reactions were observed in two trials, testing continued
              at the next higher energy level (800).
            </li>
            <li>
              Since a reaction was observed at energy level 800, testing resumed
              at the next lower energy level (700) and continued with trial #3
              since trials #1 and 2 were performed in step 2.
            </li>
            <li>
              Since a reaction was observed at energy level 700 before 20 trials
              were completed, testing resumed at energy level 600 and 20 trials
              were completed without a reaction.
            </li>
          </ol>
          <p>
            In Step 5, sufficient additional trials were obtained at energy
            level 600 to combine with the trials in step 1 to get 20 trials with
            no reaction, establishing the TIL value at energy level 600.
          </p>
          <p>
            The second objective is to establish the PROBIT data by completing
            10 trials at a minimum of four energy levels above the TIL value.
          </p>
          <ol>
            <li>
              Complete testing of 10 trials at one energy level higher than the
              TIL, in this case, start with trial # 7 at energy level 700.
            </li>
            <li>
              Complete testing of 10 trials at energy level 800 (two levels
              above the TIL).
            </li>
            <li>
              Complete testing of 10 trials at energy level 900 (three levels
              above the TIL).
            </li>
            <li>
              Complete testing of 10 trials at energy level 1000 (four levels
              above the TIL).
            </li>
          </ol>
          <p>
            This completes the testing required for the basic PROBIT Series. A
            20 trial PROBIT would increase the confidence in the test data and
            may slightly change the calculated statistical data. For this type
            of series, 20 trials at each energy level would be conducted.
          </p>
          <p>
            It should be noted that a TIL level as established by 20 no-go’s at
            a given energy input level will not necessarily be the same as a TIL
            calculated by a PROBIT analysis. The TIL calculated by the PROBIT
            analysis is the 3.4% probability point on the PROBIT line that is
            fit to all test data in the series. The TIL value from the 20 no-go
            test series is one of the test points used to establish the PROBIT
            line, and thus is not a precise measure of the 3.4% probability
            point, but is merely the lowest energy level tested that gives a 20
            no-go trial series. The accuracy of each type of test is directly
            related to the number of tests and the range of values over which
            the data are taken.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Probability of Initiation and Threshold Initiation Level",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS uses Probability of Initiation (PROBIT) and Threshold Initiation Level (TIL) Analysis to statistically characterize material sensitivity."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.probit-til {
  a {
    color: #aa222c;
  }
  h2 {
    color: black;
    font-size: 18px;
    margin-bottom: 1.1em;
  }
}
</style>
